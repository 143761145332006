import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../../common/pageheader/pageheader";
import axios from "axios";
import { serverLink } from "../../../../resources/url";
import Loader from "../../../common/loader/loader";
import { showAlert } from "../../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import LinearProgressWithLabel from "../../../common/linear-progress-bar/linear-progress-bar";
import Box from "@mui/material/Box";
import Table from "../../../common/table/table";
import Select from 'react-select';
import { formatDateAndTime } from "../../../../resources/constants";
import { useReactToPrint } from 'react-to-print';
import Barcode from "react-hooks-barcode";




function ExamBarcode(props) {
    const token = props.loginData[0].token;

    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [showProgress2, setShowProgress2] = useState(false);
    const [showProgress3, setShowProgress3] = useState(false);
    const [showBody, setshowBody] = useState(false)
    const [isFormLoading, setIsFormLoading] = useState('off');
    const [semesterList, setSemesterList] = useState([]);
    let [studentData, setStudentData] = useState([]);
    const [progress, setProgress] = useState(0);
    const [studentList, setStudentList] = useState([]);
    const [moduleList, setModuleList] = useState([]);
    const [selectedModules, setSelectedModules] = useState([]);
    const componentRef = useRef();
    const [printValue, setPrintValue] = useState('')
    const [semesterOptions, setSemesterOptions] = useState([]);
    const [showForm, setshowBarcode] = useState(false);


    const [formData, setFormData] = useState({
        StudentID: "",
        SemesterCode: "",
        SemesterCode2: "",
        SemesterCode3: "",
        ModuleCode: "",
        ModuleCode2: "",
        InsertedBy: `${props.loginData[0].StaffID}`
    });

    const [createSchedule, setcreateSchedule] = useState({
        SemesterCode: "",
        SemesterCode2: "",
    });

    const [generalDatatable, setGeneralDatatable] = useState({
        columns: [
            {
                label: "S/N",
                field: "sn",
            },
            {
                label: "Exam Hall",
                field: "HallName",
            },
            {
                label: "Seat No",
                field: "SeatNo",
            },
            {
                label: "Student ID",
                field: "StudentID",
            },
            {
                label: "Student Name",
                field: "StudentName",
            },
            {
                label: "Module Code ",
                field: "ModuleCode",
            },
            {
                label: "Module Name ",
                field: "ModuleName",
            },
            {
                label: "Attendance",
                field: "IsPresent",
            },
            {
                label: "Generated By",
                field: "StaffName",
            },
            {
                label: "Date Generated",
                field: "InsertedDate",
            },
            {
                label: "print",
                field: "action"
            }
        ],
        rows: [],
    });

    const getData = async () => {

        await axios.get(`${serverLink}staff/timetable/timetable/semester`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    let row_set = [];
                    result.data.map((item => {
                        rows.push({ id: item.SemesterCode, text: item.SemesterName })
                    }))

                    result.data.map((row) => {
                        row_set.push({ value: row.SemesterCode, label: row.SemesterName +"- "+row.SemesterCode })
                    });

                    setSemesterList(result.data);
                    setSemesterOptions(row_set)
                    setSemesterList(rows)
                }
            })

        await axios.get(`${serverLink}staff/student-manager/student/active`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((item => {
                        rows.push({ id: item.StudentID, text: `${item.FirstName} ${item.MiddleName} ${item.Surname} (${item.StudentID})` })
                    }))
                    setStudentList(rows)
                }
            }).catch((err) => {
                console.log("NETWORK ERROR");
            });

        await axios.get(`${serverLink}staff/academics/module/list`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((item => {
                        rows.push({ id: item.ModuleCode, text: `${item.ModuleName} (${item.ModuleCode})` })
                    }))
                    setModuleList(rows)
                }
            })
        setIsLoading(false)
    }

    const printBarcode = useReactToPrint({
        content: () => componentRef.current,
    })





    const getGeneralData = async (semester) => {
        setLoading(true)
        await axios.get(`${serverLink}staff/timetable/exam/barcode/list/${semester}`, token)
            .then((result) => {
                const data = result.data;
                if (data.length > 0) {
                    let rows = [];
                    data.map((item, index) => {
                        studentData.push(item.StudentID);
                        rows.push({
                            sn: index + 1,
                            HallName: item.HallName,
                            SeatNo: item.SeatNo,
                            StudentID: item.StudentID,
                            StudentName: item.StudentName,
                            ModuleCode: item.ModuleCode,
                            ModuleName: item.ModuleName,
                            IsPresent: item.IsPresent === 1 ? 'Attended' : 'Not Attended',
                            StaffName: item.StaffName,
                            InsertedDate: formatDateAndTime(item.InsertedDate, 'date'),
                            action: (
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                    setPrintValue(item.Barcode)
                                    setshowBarcode(true);
                                    setTimeout(() => {
                                        printBarcode()
                                        setshowBarcode(false);
                                    }, 100);
                                }
                                }>
                                    <i className="fa fa-print" />

                                </button>
                            )
                        });
                    });
                    setGeneralDatatable({
                        ...generalDatatable,
                        columns: generalDatatable.columns,
                        rows: rows,
                    });
                    setLoading(false)
                    setshowBody(true)
                }else{
                    setGeneralDatatable({
                        ...generalDatatable,
                        columns: generalDatatable.columns,
                        rows: [],
                    });
                    setLoading(false)
                    setshowBody(true)
                    toast.error("No record found")
                }
            })
            .catch((err) => {
                console.log("NETWORK ERROR STATE");
            });
    }


    const onEdit = (e) => {
        const options = e.target.options;
        const value = [];

        if (e.target.id === "ModuleCode") {
            for (var i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                    if (e.target.id === "ModuleCode") {
                        value.push(options[i].value);
                    }
                }
            }
            setSelectedModules(value);
        } else {
            setFormData({
                ...formData,
                [e.target.id]: e.target.value,
            });
        }
    }

    const onSubmit = async () => {
        if (formData.SemesterCode.trim() === "") {
            showAlert("EMPTY FIELD", "Please select the semester code", "error");
            return false;
        }

        setIsFormLoading('on')
        setProgress(0)
        setShowProgress(false)
        setShowProgress(true)

        let sendData = { ...formData, StudentData: studentData }
        await axios.post(`${serverLink}staff/timetable/exam/barcode`, sendData, token, {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100)
                setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + percentCompleted));
            }
        }).then((result) => {
            let duplicate = result.data.duplicate;
            if (result.data.message === "success") {
                toast.success("Exam Barcode Generated Successfully");
                showAlert("BARCODE GENERATED", `Barcode Generated Successfully! \n   ${duplicate.length > 0 ? ` However, barcode has been generated for the following student(s).: ${duplicate}` : ""}  `, "success");
                getGeneralData(createSchedule.SemesterCode);
            } else if (result.data.message === "duplicate") {
                showAlert("DUPLICATE ENTRIES", `Barcode has been generated for the following student(s), Please check and try again!  \n   ${duplicate.length > 0 ? ` ${duplicate}` : ""}  `, "error");
            } else if (result.data.message === "empty") {
                showAlert(
                    "ERROR",
                    "No exam timetable schedule for the selected semester!",
                    "error"
                );
            } else {
                showAlert(
                    "ERROR",
                    "Something went wrong. Please try again!",
                    "error"
                );
            }

        }).catch((err) => {
            console.log("NETWORK ERROR");
        });

    };

    const onModuleSubmit = async () => {
        if (formData.SemesterCode2.trim() === "") {
            showAlert("EMPTY FIELD", "Please select the semester", "error");
            return false;
        }

        if (selectedModules.length < 1) {
            showAlert("EMPTY FIELD", "Please select at least 1 module", "error");
            return false;
        }

        setIsFormLoading('on')
        setProgress(0)
        setShowProgress2(false)
        setShowProgress2(true)

        let sendData = { ...formData, StudentData: studentData, ModuleCode: selectedModules }
        await axios.post(`${serverLink}staff/timetable/exam/barcode/module`, sendData,  token,{
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100)
                setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + percentCompleted));
            }
        }).then((result) => {
            let duplicate = result.data.duplicate;
            let empty = result.data.empty_module;
            if (result.data.message === "success") {
                toast.success("Exam Barcode Generated Successfully");
                showAlert("BARCODE GENERATED", `Barcode Generated Successfully! \n   ${duplicate.length > 0 ? ` However, barcode has been generated for the following student(s).: ${duplicate}` : ""} \n\n\n   ${empty.length > 0 ? ` Also, No exam timetable schedule for the following module(s): ${empty}` : ""}   `, "success");
                getGeneralData(createSchedule.SemesterCode);
            } else if (result.data.message === "duplicate") {
                showAlert("DUPLICATE ENTRIES", `Barcode has been generated for the following student(s), Please check and try again!  \n   ${duplicate.length > 0 ? ` ${duplicate}` : ""}  `, "error");
            } else if (result.data.message === "empty") {
                showAlert(
                    "ERROR",
                    "No exam timetable schedule for the selected semester and module!",
                    "error"
                );
            } else {
                showAlert(
                    "ERROR",
                    "Something went wrong. Please try again!",
                    "error"
                );
            }

        }).catch((err) => {
            console.log("NETWORK ERROR");
        });

    };

    const onEditSemester = (e) => {
        setcreateSchedule({
            ...createSchedule,
            SemesterCode: e.value,
            SemesterCode2: e,
        });
    };

    const onSemesterChange = async (e) => {
        if (e.value !== "") {
            setcreateSchedule({
                ...createSchedule,
                SemesterCode: e.value,
                SemesterCode2: e
            })
            getGeneralData(e.value);
        } else {
            setcreateSchedule({
                ...createSchedule,
                SemesterCode: "",
                SemesterCode2: ""
            })
            setshowBody(false)
        }
    }


    const onStudentSubmit = async () => {
        if (formData.SemesterCode3.trim() === "") {
            showAlert("EMPTY FIELD", "Please select the semester", "error");
            return false;
        }

        if (formData.ModuleCode2.trim() === "") {
            showAlert("EMPTY FIELD", "Please select the module", "error");
            return false;
        }

        if (formData.StudentID.trim() === "") {
            showAlert("EMPTY FIELD", "Please select the student", "error");
            return false;
        }

        setIsFormLoading('on')
        setProgress(0)
        setShowProgress3(false)
        setShowProgress3(true)
        let sendData = { ...formData, StudentData: studentData }
        await axios.post(`${serverLink}staff/timetable/exam/barcode/student`, sendData, token, {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100)
                setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + percentCompleted));
            }
        }).then((result) => {
            let duplicate = result.data.duplicate;
            if (result.data.message === "success") {
                toast.success("Exam Barcode Generated Successfully");
                showAlert("BARCODE GENERATED", `Barcode Generated Successfully! \n   ${duplicate.length > 0 ? ` However, barcode has been generated for the following student(s).: ${duplicate}` : ""}  `, "success");
                getGeneralData(createSchedule.SemesterCode);
            } else if (result.data.message === "duplicate") {
                showAlert("DUPLICATE ENTRIES", `barcode has been generated for the following student(s), Please check and try again!  \n   ${duplicate.length > 0 ? ` ${duplicate}` : ""}  `, "error");
            } else if (result.data.message === "empty") {
                showAlert(
                    "ERROR",
                    "No exam timetable schedule for the selected semester, module and student!",
                    "error"
                );
            } else {
                showAlert(
                    "ERROR",
                    "Something went wrong. Please try again!",
                    "error"
                );
            }

        }).catch((err) => {
            console.log("NETWORK ERROR");
        });
    };

    useEffect(() => {
        getData();
        // getGeneralData();
    }, [])


    return (
        isLoading ? <Loader /> :   <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Exam Barcode"}
                items={["Assessment", "Exam", "Exam Barcode"]}
            />
            <div className="flex-column-fluid" >
                {semesterOptions.length > 0 &&
                    <div className="col-md-12 mb-4 form-group">
                        <label htmlFor="_Semester">Select Semester</label>
                        <Select
                            id="_Semester"
                            className="form-select form-select"
                            value={createSchedule.SemesterCode2}
                            onChange={onSemesterChange}
                            options={semesterOptions}
                            placeholder="select Semester"
                        />

                    </div>}
                {
                    loading ?
                        <div
                            className="card-body pt-7"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <Loader/>
                        </div>
                        :
                        showBody === true &&
                        <div className="card">
                            <div className="card-body">
                                {showForm &&
                                    <div className="col-md-12 m-5 pt-5" id="exam_barcode" ref={componentRef}
                                         style={{textAlign: 'center'}}>
                                        <Barcode value={printValue} className="mt-5 pt-5"/>
                                    </div>
                                }
                                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">

                                    <li className="nav-item">
                                        <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab"
                                           href="#semester">General</a>
                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link text-active-primary pb-4" data-kt-countup-tabs="true"
                                           data-bs-toggle="tab" href="#module">Module</a>
                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                                           href="#student">Student</a>
                                    </li>

                                </ul>

                                <div className="tab-content" id="myTabContent">

                                    <div className="tab-pane fade active show" id="semester" role="tabpanel">
                                        <div className="d-flex col-md-12">
                                            <div className="col-md-11 form-group">
                                                <Select2
                                                    id="SemesterCode"
                                                    name="SemesterCode"
                                                    data={semesterList}
                                                    className={"form-control"}
                                                    value={formData.SemesterCode}
                                                    onSelect={onEdit}
                                                    options={{
                                                        placeholder: "Search Semester",
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <button
                                                    className="btn btn-light btn-active-light-primary fw-bolder flex-shrink-0"
                                                    onClick={onSubmit} style={{marginLeft: '10px'}}>Generate
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            showProgress ?
                                                <Box sx={{width: '100%', marginTop: '20px'}}>
                                                    <LinearProgressWithLabel value={progress}/>
                                                </Box>
                                                : <></>
                                        }
                                        <Table data={generalDatatable}/>
                                    </div>
                                    <div className="tab-pane fade" id="module" role="tabpanel">
                                        <div className={"row"}>
                                            <div className="form-group col-md-5 mb-4">
                                                <label htmlFor="SemesterCode2">Semester</label>
                                                <Select2
                                                    id="SemesterCode2"
                                                    name="SemesterCode2"
                                                    data={semesterList}
                                                    value={formData.SemesterCode2}
                                                    className={"form-control"}
                                                    onSelect={onEdit}
                                                    options={{
                                                        placeholder: "Search Semester",
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-5 mb-4">
                                                <label htmlFor="ModuleCode">Module</label>
                                                <Select2
                                                    id="ModuleCode"
                                                    name="ModuleCode"
                                                    data={moduleList}
                                                    defaultValue={selectedModules}
                                                    multiple
                                                    className={"form-control"}
                                                    onSelect={onEdit}
                                                    options={{
                                                        placeholder: "Search Module",
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group  col-md-2 pt-6 ">
                                                <button onClick={onModuleSubmit} className="btn btn-primary w-100">
                                                    Generate
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            showProgress2 ?
                                                <Box sx={{width: '100%', marginTop: '20px'}}>
                                                    <LinearProgressWithLabel value={progress}/>
                                                </Box>
                                                : <></>
                                        }
                                        <Table data={generalDatatable}/>
                                    </div>
                                    <div className="tab-pane fade" id="student" role="tabpanel">
                                        <div className={"row"}>
                                            <div className="form-group col-md-3 mb-4">
                                                <label htmlFor="SemesterCode3">Semester</label>
                                                <Select2
                                                    id="SemesterCode3"
                                                    name="SemesterCode3"
                                                    data={semesterList}
                                                    value={formData.SemesterCode3}
                                                    className={"form-control"}
                                                    onSelect={onEdit}
                                                    options={{
                                                        placeholder: "Search Semester",
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-3 mb-4">
                                                <label htmlFor="ModuleCode2">Module</label>
                                                <Select2
                                                    id="ModuleCode2"
                                                    name="ModuleCode2"
                                                    data={moduleList}
                                                    value={formData.ModuleCode2}
                                                    className={"form-control"}
                                                    onSelect={onEdit}
                                                    options={{
                                                        placeholder: "Search Module",
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-3 mb-4">
                                                <label htmlFor="StudentID">Student</label>
                                                <Select2
                                                    id="StudentID"
                                                    name="StudentID"
                                                    data={studentList}
                                                    value={formData.StudentID}
                                                    className={"form-control"}
                                                    onSelect={onEdit}
                                                    options={{
                                                        placeholder: "Search Student",
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group  col-md-3 pt-6 ">
                                                <button onClick={onStudentSubmit} className="btn btn-primary w-100">
                                                    Generate
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            showProgress3 ?
                                                <Box sx={{width: '100%', marginTop: '20px'}}>
                                                    <LinearProgressWithLabel value={progress}/>
                                                </Box>
                                                : <></>
                                        }
                                        <Table data={generalDatatable}/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header border-0 pt-6">
                                <div className="card-title"/>
                                <div className="card-toolbar">
                                </div>

                            </div>
                            <div className="card-body pt-7" style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}>

                                {
                                    isLoading ? <Loader/> : ""
                                }

                            </div>
                        </div>
                }


            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ExamBarcode);

